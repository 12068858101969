body {
  font: 62.5%/1;
  margin: 0 auto;
  padding: 24px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.header {
  text-transform: uppercase;
  text-shadow: 0 0 1px #fff;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
p {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

abbr {
  cursor: help;
}

a {
  color: #000;
  text-decoration: none;
  -webkit-transition: background-color, border-color, color;
  -webkit-transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
}

a:hover {
  color: #e52222;
}

iframe {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

.no-wrap {
  white-space: nowrap;
}

h2 {
  font-size: 28px;
  margin-bottom: 16px;
}

.clear {
  clear: both;
}

.prose {
  font-size: 16px;
  line-height: 24px;
}

.prose p, ul, ol {
  margin-bottom: 16px;
}

.prose ul {
  margin-left: 1.2em;
  list-style-type: square;
}

.prose ol {
  margin-left: 1.3em;
  list-style-type: decimal;
}

.prose .heading {
  margin-top: 24px;
  margin-bottom: 0;
}

.prose h2,
.prose h3 {
  margin-bottom: 5px;
}

.prose h2 {
  font-size: 24px;
}

.prose h3 {
  font-size: 18px;
}

.prose img {
  margin-top: 8px;
}

.prose a {
  border-bottom: 1px dashed;
}

.prose a:hover,
.prose a:focus {
  color: #e52222;
}

.prose a:active {
  color: #ef4948;
}

.prose .image {
  border: none;
}

.prose .bordered {
  padding: 4px;
  border: 1px solid #ccc;
}

.prose .bordered:hover {
  border-color: rgba(239,73,72,0.2);
  background-color: rgba(239,73,72,0.1);
}

.prose .bordered:active {
  border-color: #e52222;
  background-color: #ef4948;
}

.bordered {
  display: inline-block;
  padding: 4px;
  border: 1px solid #ccc;
}

.bordered:hover {
  border-color: rgba(239,73,72,0.4);
  background-color: rgba(239,73,72,0.25);
}

.bordered:active {
  border-color: #e52222;
  background-color: #ef4948;
}

.bordered img {
  margin: 0;
}

.lanyrd-link {
  position: relative;
  top: -4px;
  left: 0px;
  display: inline-block;
  width: 32px;
  height: 32px;
  text-indent: -900em;
  background: url(../img/lanyrd-48x48.png) no-repeat  center center;
  -webkit-background-size: 16px 16px;
  -moz-background-size: 16px 16px;
  background-size: 16px 16px;
  z-index: 1;
  margin-bottom: -8px;
  vertical-align: text-top;
}

.read-more a:before {
  content: "» ";
  float: left;
  margin-right: 4px;
}

.module {
  margin-bottom: 20px;
  font-size: 16px;
  clear: left;
}

.module:after,
.column:after {
  content: "---";
  display: block;
  text-align: center;
  color: #ccc;
  margin-top: 20px;
  width: 100%;
  clear: left;
}

.column:last-of-type:after {
  content: "";
}

.module:last-of-type:after {
  content: "";
}

.module-heading {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 16px;
  color: #9d999e;
}

.heading:first-of-type {
  margin-top: 0;
}

.masthead {
  position: relative;
  margin-bottom: 32px;
}

.masthead:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
}

.masthead h1,
.masthead p {
  float: left;
}

.masthead h1 {
  text-transform: none;
  font-size: 48px;
  margin-right: 8px;
}

.masthead p {
  position: relative;
  top: 16px;
  font-size: 16px;
  width: auto;
  line-height: 20px;
  color: #555;
}

.masthead p em {
  font-style: normal;
  display: block;
}

.masthead .logo {
  display: none;
}

.event-detail header,
.event-detail section {
  margin-bottom: 16px;
}

.event-detail .heading {
  margin-bottom: 4px;
  display: inline-block;
  padding-right: 16px;
}

.event-detail .heading:hover a {
  color: #e52222;
}

.event-detail .lanyrd-link {
  top: 1px;
  left: 5px;
  width: 32px;
  height: 32px;
  -webkit-background-size: 20px 20px;
  -moz-background-size: 20px 20px;
  background-size: 20px 20px;
}

.event-detail .meta {
  font-size: 18px;
}

.event-detail .meta a {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
}

.event-detail .description {
}

.event-detail .description:before,
.event-detail .description:after {
    content: "";
    display: table;
}

.event-detail .description:after {
    clear: both;
}

.event-detail .bordered {
  margin-bottom: 8px;
}

.event-detail .vevent {
  font-size: 16px;
  line-height: 20px;
  position: relative;
  padding: 14px 0 12px 58px;
  min-height: 40px;
  border: 1px dashed #ccc;
  border-left: 0;
  border-right: 0;
  margin: 0 0 8px;
}

.event-detail .vevent .location a {
}

.event-detail .vevent .date {
  margin-top: -2px;
  display: block;
}

.event-detail .vevent .adr {
  display: none;
}

.event-detail .vevent .map {
  position: absolute;
  top: 8px;
  left: 0px;
  display: block;
}

.event-detail .vevent .map img {
  max-width: 40px;
  max-height: 40px;
}

.event-detail .sponsors {
  position: absolute;
  right: 0;
  top: 7px;
  position: static;
  overflow: hidden;
  margin-left: -58px;
  border-top: 1px dashed #ccc;
  margin-top: 13px;
  padding-top: 8px;
}

.event-detail .sponsor-logo {
  float: right;
  float: none;
}

.event-detail .sponsor-logo img {
  display: block;
  max-height: 52px;
  max-width: auto;
}

.event-item {
  margin-bottom: 16px;
}

.event-item h3 {
  display: inline-block;
  font-size: 18px;
  margin-bottom: 4px;
  line-height: 1.2;
}

.event-item:first-of-type h3 {
  margin-top: 0;
}

.event-item h3:hover a {
  color: #e52222;
}

.event-item .details {
  margin-bottom: 8px;
}

.event-item .lanyrd-simple {
  font-size: 13px;
  line-height: 1;
  text-transform: uppercase;
  color: #2e2e2e;
}

.links li {
  display: inline-block;
  margin-bottom: 8px;
}

.links li:after {
  content: " •";
  padding: 0 8px;
  color: #808080;
}

.links li:last-child:after {
  content: "";
}

.links li a {
  border-bottom: 1px dashed;
}

.news {
  margin-top: -20px;
}

.news:first-of-type {
  margin-top: 0;
}

.news:after {
  content: "";
  margin: 0;
}

.news a {
  display: block;
  padding: 8px 0;
  border-bottom: 1px dashed #ccc;
  height: auto;
  line-height: 1;
}

.news:first-of-type a {
  border-top: 1px dashed #ccc;
}

.news:hover a {
  color: #000;
  border-color: #aaa;
}

.news strong {
  text-transform: uppercase;
  font-weight: normal;
}

.news-item .read-more {
  margin-top: -16px;
}

footer {
  line-height: 1.5;
}

footer,
footer a {
  font-size: 12px;
  color: #aaa;
  margin-top: 32px;
  margin-bottom: 16px;
}

footer a {
  border-bottom: 1px dashed;
}

footer span {
  white-space: nowrap;
}

.dharmafly {
  font-family: Calibri, Helvetica, Arial, sans-serif;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  padding: 1px;
  font-size: 11px;
}

.dharmafly:hover,
.dharmafly:focus {
  color: #A6C664;
}

.dharmafly:active {
  color: #fff;
  background-color: #A6C664;
  border-bottom: none;
}

.extra .heading {
  margin-bottom: 8px;
  margin-top: 0;
}

.lanyrd-people {
  list-style-type: none;
  overflow: hidden;
  position: relative;
  left: -8px;
  margin-bottom: 0;
}

.lanyrd-people {
  margin-bottom: 8px;
}

.lanyrd-people li {
  float: left;
  margin-left: 8px;
}

.lanyrd-people li a {
  width: 50px;
}

.lanyrd-trackers li a {
  width: 35px;
}

.hubbub {
  margin-top: 24px;
}

.hubbub-avatar {
  width: 40px;
}

.hubbub-avatar-link {
  float: left;
  margin-top: 4px;
}

.hubbub-content {
  margin-left: 60px;
}

.hubbub-timestamp {
  float: right;
}

.hubbub-header a {
  margin-right: 5px;
}

.hubbub-comment-body p {
  margin-top: 8px;
  margin-bottom: 16px;
}

.hubbub-comment-body a {
  border-bottom:1px dashed;
}

.iframe-wrapper {
  position: relative;
  padding-bottom: 65%;
  margin-bottom: 20px;
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  max-width: 100%;
}

.iframe-slides .iframe {
  width: 200%;
  height: 200%;
  max-width: 200%;
  -webkit-transform: scale(0.5);
  -webkit-transform-origin: top left;
  -moz-transform: scale(0.62);
  -moz-transform-origin: 0;
  -o-transform: scale(0.62);
  -o-transform-origin: 0;
  -ms-transform: scale(0.62);
  -ms-transform-origin: 0;
  transform: scale(0.62);
  transform-origin: 0;
}

#media-test {
  display: none;
  visibility: hidden;
  width: 0;
}

@media (min-width:480px) {
  body {
    margin: 0 auto;
    padding-left: 5%;
    padding-right: 5%;
  }

  h2 {
    font-size: 36px;
  }

  .masthead {
    position: relative;
    margin-bottom: 32px;
    padding-left: 50px;
  }

  .masthead .logo {
    position: absolute;
    top: 14px;
    left: -5px;
    display: block;
  }

  .event-detail .description.image {
    position: relative;
  }

  .event-detail .description.image .bordered {
    float: right;
    top: 0;
    left: 60%;
    right: 0;
    width: 45%;
    margin-top: 4px;
    margin-left: 3%;
    margin-bottom: 8px;
  }

  #media-test {
    width: 1px;
  }

  .event-detail .sponsors {
    position: absolute;
    right: 0;
    top: 7px;
    border-top: none;
    padding: 0;
    margin: 0;
  }

  .event-detail .sponsors .sponsor-logo {
    float: right;
  }
}

@media (min-width:560px) {
  body {
    padding-left: 10%;
    padding-right: 10%;
  }

  .masthead h1,
  .masthead p {
    position: static;
    float: none;
    display: inline;
  }

  .masthead p em {
    display: inline;
  }
}

@media (min-width:840px) {
  .column {
    float: left;
    box-sizing: border-box;
    width: 45%;
    margin-right: 5%;
  }

  .column:nth-child(odd) {
    clear: left;
  }

  .column:after {
    content: "";
  }

  .column.event-item h3 {
    margin-top: 0;
  }
}

@media (min-width:820px) {
  .masthead p {
    font-size: 24px;
  }
}

@media (min-width:840px) {
  body {
    margin-left: auto;
    margin-right: auto;
    max-width: 720px;
  }
}

body, .masthead p em {
  font-family: "Catamaran", Helvetica, Arial, Verdana , sans-serif;
  font-weight: normal;
}

i, em {
  font-family:"Catamaran", Helvetica, Arial, Verdana , sans-serif;
  font-style: italic;
}

b, strong,
.masthead h1,
.read-more a,
.event-detail .vevent,
.event-item .lanyrd-simple {
  font-family:"Catamaran", Helvetica, Arial, Verdana , sans-serif;
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6,
.header,
.news strong {
  font-family: "Anton", Verdana, sans-serif;
  font-weight: normal;
  text-rendering: optimizeLegibility; // May cause issues in Windows browsers
}
